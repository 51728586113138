import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Title from "../components/title"
import Card from '../components/card'

// import resumeImg from "../images/resumeImg.png"
import resumePDF from "../images/resumelong.pdf"

const resume = () => (
  <Layout>
    <Card style={{ width: '100%', height: '1000px', padding: '20px', marginTop: '30px' }}>
      <Title>Resume</Title>
      <a href={resumePDF}>click here to
        download a pdf.</a>
      {/* <img src={resumeImg} /> */}
      {/* <embed src={resumePDF} type="application/pdf" width="100%" height="1200px" /> */}
      {/* <iframe 
          src="https://docs.google.com/viewer?srcid=1HorBNtdZxiJE_50vdXi2jEVd72vryvL9&pid=explorer&efh=false&a=v&chrome=false&embedded=true"
          width = '100%'
          height= '100%'
      >
      </iframe> */}
      <object data={resumePDF} type="application/pdf" width="100%" height="1200px">
        <p>Your web browser doesn't have a PDF plugin.
        Instead you can <a href={resumePDF}>click here to
        download the PDF file.</a></p>
      </object>
    </Card>
  </Layout>

)

export default resume